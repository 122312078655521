@import '~@/less/variable.less';

@font-face {
  font-family: 'Inter'; /* 自定义字体的名称 */
  src: url('~@/assets/fonts/Inter-Black-3.otf'); /* 字体文件的URL和格式 */
}

@font-face {
  font-family: 'Puhui'; /* 自定义字体的名称 */
  src: url('~@/assets/fonts/ali-puhuiti.ttf'); /* 字体文件的URL和格式 */
}

@font-face {
  font-family: 'Anton'; /* 自定义字体的名称 */
  src: url('~@/assets/fonts/Anton-Regular-2.ttf'); /* 字体文件的URL和格式 */
}

.wrapper {
  display: flex;
  flex-flow: column;
  padding-top: 100px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  background: #170245;
  opacity: 1;
  color: #fff;
}

@media screen and (max-width: 750px) {
  .wrapper {
    padding-top: 65px;
  }
}

.layout {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;