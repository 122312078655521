.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #170245;
  z-index: 100;
}

@media screen and (max-width: 750px) {
  .header {
    display: none;
  }
}

.main {
  margin-left: 57px;
  margin-right: 34px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1400px) {
    margin: 0;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 50px;
  flex: auto;
  @media screen and (max-width: 1400px) {
    gap: 20px;
  }
}

.right {
  display: flex;
}

.download {
  margin-left: 34px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.logo {
  width: 144px;
  cursor: pointer;
  align-self: flex-end;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;