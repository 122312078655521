.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 100;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  background-color: #170245;
  height: 65px;
}

@media screen and (min-width: 750px) {
  .header {
    display: none;
  }
}

.logo {
  width: 103px;
}

.right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.avatar {
  width: 33px;
  height: 33px;
  border-radius: 100%;
}

.icon {
  width: 33px;
  height: 33px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;