.dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background-color: #0e0129;
  visibility: hidden;
  max-height: 80vh;
  overflow-y: auto;
  &.show {
    visibility: visible;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;