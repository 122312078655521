.button {
  color: #fff;
  height: 46px;
  cursor: pointer;
  background-image: linear-gradient(90deg, #9a6bfe, #fc78ff);
  border: none;
  outline: none;
  border-radius: 100px;
  display: block;
  position: relative;
  &:hover {
    background-image: linear-gradient(90deg, #a87eff, #fd99ff);
  }
  &:disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }
}

.borderOnly {
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(#271d65, #271d65),
    linear-gradient(90deg, #9a6bfe, #fc78ff);

  &:hover {
    background-image: linear-gradient(#271d65, #271d65),
      linear-gradient(90deg, #ab80ff, #fa98ff);
  }
}

.content {
  position: relative;
  z-index: 1;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;