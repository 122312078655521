@import '~@/less/variable.less';

.langCtn {
  display: flex;
  align-items: center;
  height: @header-line-height;
}

.arrow {
  width: 16px;
  transform: rotate(-90deg);
  margin-left: 10px;
  transition: transform 0.2s;
}

.rotateArrow {
  transform: rotate(0);
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;