@import '~@/less/variable.less';

.links {
  padding: 0 24px;
  margin-bottom: 30px;
}

.link {
  color: #fff;
  display: block;
  height: @header-line-height;
  line-height: @header-line-height;
}

.activeLink {
  color: #fff;
  font-weight: bold;
  color: #f35caf !important;
}

.lotteryCtn {
  display: inline-flex;
  align-items: center;
}

.lotteryIcon {
  width: 18px;
  margin-right: 4px;
}

.colorfulText {
  font-weight: bold;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(to bottom, #ffffff, #ff87c9);
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;